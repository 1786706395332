import * as helpers from './Helpers';

// Allowed can be a string or an array of strings
// All values will be change to lowercase for comparision
// Returns true if allowed is undefined
// Returns false if value is undefined
export function validateString(value, allowed) {
  if (!allowed) { return true; }
  if (!value) { return false; }
  const allowedLowerCase = [allowed].flat().map( v => v.toLowerCase());
  return allowedLowerCase.includes(value.toLowerCase());
}

// Allowed values can be a number or an array of 2 number
// Returns false if value is undefined
// Returns true if allowed is undefined
// Examples of allowed:
//     [1,100]: 1 <= value <= 100
//  [null,100]:      value <= 100
//    [1,null]: 1 <= value
//         [1]: 1 <= value
//       [1,1]:      value == 1
//           1:      value == 1
export function validateNumericRange(value, allowed) {
  if (value !== 0 && !value) { return false; }
  if (!allowed) { return true; }
  // keep 0's change other falsy values to null
  const allowedArray = [allowed].flat().map( v => v ? v : ( (v === 0) ? 0 : null ));
  if (allowedArray.length < 2) {
    allowedArray.push(null);
  }
  const lowerBound = allowedArray[0];
  const upperBound = Array.isArray(allowed) ? allowedArray[1] : lowerBound;
  if (lowerBound != null && value < lowerBound) {
    return false;
  }
  if (upperBound != null && value > upperBound) {
    return false;
  }
  return true;
}

export function rangeValidationString(range) {
  const comma = helpers.commaNumber;
  if (Array.isArray(range) && range[0] !== range[1]) {
    if ([undefined, null].includes(range[0]) && [undefined, null].includes(range[1])) {
      return `Range unknown`;
    } else if ([undefined, null].includes(range[0])) {
      return `Must be less than  ${comma(range[1])}`;
    } else if ([undefined, null].includes(range[1])) {
      return `Must be greater than  ${comma(range[0])}`;
    } else {
      return `Must be between ${comma(range[0])} and ${comma(range[1])}`;
    }
  } else {
    const value = Array.isArray(range) ? range[0] : range;
    return `Must equal ${value}`
  }
}

