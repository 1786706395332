import Papa from 'papaparse'

export default function add({tool, cgv, inputs, mapData}) {

  // Swap start/stop posisitions 
  if (inputs.swapStartStop) {
    for (const feature of mapData.features) {
      if (feature.start > feature.stop) {
        [feature.start, feature.stop] = [feature.stop, feature.start];
      }
    }
  }

  mapData = {
    ...mapData,
    sourcePrefix: `file-${inputs.file.fileName}-`,
  }

  return { ok: true, mapData };


  // OLD WAY but here as a refereence for now
  // // Read Feature data from csv text
  //TODO: create helper for reading csv with the following defaults
  // const text = inputs.file.text;
  // const csv = Papa.parse(text, {
  //   header: true,
  //   skipEmptyLines: true,
  //   comments: '#',
  //   transformHeader: (h) => h.trim().toLowerCase(),
  //   transform: (d) => d.trim(),
  //   error: (error, file) => {console.log(error)}
  // });
  // [name, type, start, stop, strand]
  // console.log(csv)
  // Create Features
  // mapData = {
  //   features: csv.data,
  //   sourcePrefix: `file-${inputs.file.fileName}-`,
  // }
  // return { ok: true, mapData };
}

