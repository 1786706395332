import { RESET_STATE, FEATURES_ADD, FEATURES_REMOVE, FEATURES_UPDATE, FEATURES_UPDATE_STATS } from '../constants/ActionTypes'
import { removeItems } from './ReduxHelpers';

const initialState = {
  byID: {},
  ids: [],
};

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case RESET_STATE:           return reset(state, payload);
    case FEATURES_ADD:          return addFeatures(state, payload);
    case FEATURES_REMOVE:       return removeItems(state, payload.features);
    case FEATURES_UPDATE:       return updateFeatures(state, payload);
    case FEATURES_UPDATE_STATS: return updateFeatureStats(state, payload);
    default:                    return state;
  }
};

const reset = () => { return (initialState) }

const addFeatures = (state, { features }) => {
  // Normalize feature data
  const data = {};
  const ids = [];
  for (var i = 0, len = features.length; i < len; i++) {
    const feature = features[i];
    const json = feature.toJSON({includeDefaults: true});
    json.cgvID = feature.cgvID;
    json.legendID = feature.legend.cgvID;
    // json.itemCount = feature.itemCount;
    // json.legendItem = feature.legendItem.cgvID;
    json.length = feature.length;
    json.tags = json.tags && Array.isArray(json.tags) ? json.tags : [json.tags];
    // json.tracks = feature.tracks().map( t => t.name );
    json.tracks = feature.tracks().map( t => t.name ).join(', ')

    data[json.cgvID] = json;
    ids.push(json.cgvID);
  }

  return ({
    byID: {...state.byID, ...data},
    ids: [...state.ids, ...ids],
  })
}

const updateFeatures = (state, { features, attributes, updates }) => {
  // Normalize Feature Data
  const featureData = {};
  for (var i = 0, len = features.length; i < len; i++) {
    const feature = features[i];
    const json = feature.toJSON({includeDefaults: true});
    json.cgvID = feature.cgvID;
    json.legendID = feature.legend.cgvID;
    // json.legendItem = feature.legendItem.cgvID;
    json.length = feature.length;
    json.tags = json.tags && Array.isArray(json.tags) ? json.tags : [json.tags];
    // FIXME: tracks are not up to date, if taken this way
    // FIXME: Tracks must be referenced to Redux Tracks
    json.tracks = feature.tracks().map( t => t.name ).join(', ')
    // json.tracks = feature.tracks().map( t => t.cgvID );

    featureData[json.cgvID] = json;
  }

  return ({
    byID: {...state.byID, ...featureData},
    ids: state.ids,
  })
}

// Hopefully this won't be too slow with tons of features
const updateFeatureStats = (state, { cgv }) => {
  const newStateByID = {...state.byID};
  for (const [key, value] of Object.entries(newStateByID)) {
    const feature = cgv.objects(value.cgvID);
    newStateByID[key].tracks = feature.tracks().map( t => t.name ).join(', ')
  }
  return ({
    byID: newStateByID,
    ids: state.ids,
  })
}

