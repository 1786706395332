import React from 'react';
import PropTypes from 'prop-types';
import { CGViewContext } from '../app/CGViewContext';
import classNames from 'classnames';
import DataElement from '../presenters/DataElement';
import DataElementGroup from '../presenters/DataElementGroup';
import TextElement from '../presenters/TextElement';
import ServerAPI from '../models/ServerAPI';
import VirtualList from '../presenters/VirtualList';
import SearchBar from '../presenters/SearchBar';
import { Column } from '../presenters/VirtualTable';
import { ListItem } from '../presenters/ListItem';
import Button from '../presenters/Button';
import Dialog from '../presenters/Dialog';
import Tools from '../models/Tools';
import Message from '../presenters/Message';
import Toast from '../presenters/Toast';
import { categoryMap } from '../constants/ToolDefinitions';
import { ExtractSequenceRanges } from '../containers/SequenceSelector';
import { ConnectedJobTab } from '../tabs/JobTab';
import './ToolPane.css';
import '../support/CommonStyles.css'; 
import iconChevron from '../images/icon-chevron.png';
// import * as helpers from '../support/Helpers';


class ToolPane extends React.Component {

  // static propTypes = {
  //   tools:   PropTypes.array,
  //   searchString: PropTypes.string,
  // }
  //
  // static defaultProps = {
  //   tools: TestTools,
  // }

  constructor(props) {
    super(props);

    this.tools = new Tools();

    this.state = {
      infoOpenByID: {},
      categoryClosedByID: {},
      activeToolID: undefined,
      searchBarRef: React.createRef(),
      filteredToolIDs: this.tools.list.map( t => t.id ),
    }

    this.listItemMainRenderer = this.listItemMainRenderer.bind(this);
    this.listItemInfoRenderer = this.listItemInfoRenderer.bind(this);
    this.infoClicked = this.infoClicked.bind(this);
    this.onToolClosed = this.onToolClosed.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }


  listItemMainRenderer(tool) {
    const titleClass = classNames('list-item-title');
    const btnTitle = (tool.worker === 'server') ? 'Start' : 'Add';
    const tags = tool.tags.map( (tag) => <span className={`ps-tag ps-tag-${tag.toLowerCase()}`} key={tag}>{tag}</span> )
    return (
      <div className='list-item-content'>
        <div className={titleClass}>
          {tool.name}
          {tags}
        </div>
        <div className='tool-open'>
          <Button
            className='btn-tool-open'
            width={42}
            height={20}
            onClick={ () => this.clickOpenTool(tool) }
          >{btnTitle}</Button>
        </div>
      </div>
    );
  }

  listItemInfoRenderer(tool) {
    const software = tool.software && (
      <DataElement label='Software' helpPath='help:tools:software'>
        <TextElement>{tool.renderSoftware()}</TextElement>
      </DataElement>
    );
    const citation = tool.citation && (
      <DataElement label='Citation' helpPath='help:tools:citation'>
        <TextElement>{tool.renderCitation()}</TextElement>
      </DataElement>
    );
    return (
      <div>
        <DataElement label='Description' helpPath='help:tools:description'>
          <TextElement>{tool.description}</TextElement>
        </DataElement>
        {software}
        {citation}
        <DataElement label='Tool Version (Proksee)' helpPath='help:tools:version'>
          <TextElement>{tool.renderToolVersionLink()}</TextElement>
        </DataElement>
      </div>
    )
  }

  infoClicked(id) {
    this.setState({
      infoOpenByID: {
        ...this.state.infoOpenByID,
        [id]: !this.state.infoOpenByID[id]
      }
    });
  }

  toggleCategory(category) {
    this.setState({
      categoryClosedByID: {
        ...this.state.categoryClosedByID,
        [category]: !this.state.categoryClosedByID[category]
      }
    });
  }

  clickOpenTool(tool) {
    this.setState({activeToolID: tool.id});
  }

  // Called when the tool start dialog is closed
  onToolClosed({action, options, dialog, mapData} = {}) {
    const cgv = this.context.cgv;
    // console.log(options)
    const toolID = this.state.activeToolID;
    if (action === 'cancel') {
      this.setState({activeToolID: undefined});
    } else if (action === 'ok') {
      const tool = this.tools.get(toolID);
      const cgvData = tool.getCGViewData(cgv);
      // const {jobName, ...sanitizedOptions} = options;

      if (tool.worker === 'server') {
        // We have to use a FormData object so that we can send any files
        const data = new FormData();
        data.append('job[name]', tool.jobName(options));
        data.append('job[tool_id]', toolID);
        data.append('job[tool_version]', tool.version);
        // FILES
        // input_id is added to the files_attributes so that the server can associate
        // the file with the appropriate tool input.
        for (const fileInput of tool.fileInputs()) {
          const fileID = fileInput.id;
          if (options[fileID]) {
            const pfile = options[fileID];
            // Remove pfile from options. The file will be added back on the server
            delete options[fileID];
            // console.log(pfile)
            data.append(`job[files_attributes][][${pfile.modelAttribute()}]`, pfile.fileValue);
            // Currently we only take external files from ncbi
            if (pfile.fileSource === 'ncbi') {
              data.append('job[files_attributes][][external_db]', 'ncbi');
            }
            data.append('job[files_attributes][][source]', pfile.dataFileSourceTemp());
            data.append('job[files_attributes][][input_id]', fileID);
          }
        }
        // Add SequenceSelector file data
        for (const seqSelectorInput of tool.sequenceSelectorInputs()) {
          const inputID = seqSelectorInput.id;
          if (options[inputID]) {
            const seqSelectorOptions = options[inputID];
            const seqRegions = new ExtractSequenceRanges(cgv, seqSelectorOptions);
            const fasta = seqRegions.asFasta();
            options[inputID].fileData = fasta;
          }
        }
        // Inputs have to be sent as string. The server will convert back to a Hash.
        data.append('job[inputs]', JSON.stringify({...options, ...cgvData}));
        this.submitServerJob(tool, data, dialog);
      } else if (tool.worker === 'client') {
        // const data = { tool_id: toolID, tool_version: tool.version, inputs: {...options, ...cgvData} };
        // // this.submitClientJob(tool, data, dialog);
        // this.submitClientJob(tool, data, dialog, options, mapData);
        const inputs = { ...options, ...cgvData };
        // this.submitClientJob(tool, data, dialog);
        this.submitClientJob({tool, inputs, dialog, mapData});
      } else {
        console.error(`Unknown tool worker: '${tool.worker}'`)
      }
    }
  }

  submitServerJob(tool, data, dialog) {
    Message.open({content: "Submitting data..."})
    const Server = new ServerAPI();
    Server.post(Server.URL.jobCreate, data, {formData: true})
    .then( response => {
      if (response.ok) {
        const job = response.json;
        this.setState({activeToolID: undefined});
        Toast.create('Job successfully started', 1000);
        this.context.sidebarRef.autoCloseSideBar();
        this.context.tabsRef.add({title: job.name, id: `job-${job.id}`, content: <ConnectedJobTab id={job.id} summaryOpen={true} initialJobCard='log' />});
      } else {
        dialog.showErrors(response.json);
      }
    })
    .finally( () => {
      Message.close();
    })
  }

  // submitClientJob(tool, data, dialog) {
  submitClientJob({tool, inputs, dialog, mapData}) {
    const serverData = { tool_id: tool.id, tool_version: tool.version, inputs };
    const response = tool.handleAddConfirmed({cgv: this.context.cgv, mapData, inputs});
    if (response.ok) {
      this.setState({activeToolID: undefined});
      if (response.mapData) {
        const tabs = this.context.tabsRef;
        tabs.setTabByID('map');
        Toast.create('Results added to the map', 1000);
      } else {
        Toast.create('No results added to the map', 1000);
      }
    } else {
      dialog.showErrors([response.error]);
      serverData.error = response.error;
    }

    // Remove file text from data sent to server
    for (const fileInput of tool.fileInputs()) {
      const fileID = fileInput.id;
      serverData.inputs[fileID].text = `Length: ${serverData.inputs[fileID].text.length}`
    }
    const Server = new ServerAPI();
    Server.post(Server.URL.clientToolRun, serverData);
  }

  searchBar() {
    const { initialSearch } = this.props;
    const { searchBarRef } = this.state;
    const tools = this.tools.list;
    // console.log(tools)
    // const columns = [
    //   {label: 'Category', dataKey: 'category'},
    //   {label: 'Name', dataKey: 'name'},
    //   {label: 'Description', dataKey: 'description'},
    //   {label: 'Author', dataKey: 'author'},
    // ];
    const columns = [
      <Column label='Category' dataKey='category' />,
      <Column label='Name' dataKey='name' />,
      <Column label='Description' dataKey='description' />,
      <Column label='Author' dataKey='author' />,
    ];
    return (
      <SearchBar boundaryRef={this.containerRef} ref={searchBarRef} data={tools} columns={columns} onSearch={this.onSearchChange} initialSearch={initialSearch} />
    )
  }

  onSearchChange(filteredTools) {
    console.log(filteredTools);
    this.setState({
      filteredToolIDs: filteredTools.map( t => t.id ),
    })
  }

  searchWithString(string) {
    this.state.searchBarRef.current.searchWithString(string);
  }

  renderToolList() {
    const { filteredToolIDs } = this.state;
    const tools = this.tools
    const userRole = this.context.userRole;

    const toolList = tools.categories.map( (category) => {
      const categoryDisplayName = categoryMap[category];
      let items = tools
      .forCategory(category)
      .filter( t => t.enabled !== false || ['tester', 'admin'].includes(userRole) )
      // .filter( t => t.enabled !== false )
      .filter( t => filteredToolIDs.includes(t.id) )
      .map( tool => (
        <ListItem key={tool.id}
          mainRenderer={ () => this.listItemMainRenderer(tool) }
          infoRenderer={ () => this.listItemInfoRenderer(tool) }
          infoOpen={ this.state.infoOpenByID[tool.id] }
          infoClicked={ () => this.infoClicked(tool.id) }
        />
      ));

      const toggleStatus = {closed: this.state.categoryClosedByID[category]};
      const headingClass = classNames('category-heading', toggleStatus);
      const listClass = classNames('category-list', toggleStatus);

      return (
        <div className='category-section' key={category}>
          <div className={headingClass} onClick={() => this.toggleCategory(category)}>
            <div className='category-number'>
              {items.length}
            </div>
            <div className='toggle-and-text'>
              <div className='toggle'>
                <img src={iconChevron} width='15' height='9' alt='open/close' />
              </div>
              <div className='category-text'>
                {categoryDisplayName}
              </div>
            </div>
          </div>
          <div className={listClass}>{items}</div>
        </div>
      );
    });
    return toolList;
  }

  render() {
    let  activeToolDialog;
    if (this.state.activeToolID) {
      // This will be somewhere else
      activeToolDialog = this.tools.get(this.state.activeToolID).openDialog(this.onToolClosed);
    }

    return (
      <div className='ToolPane' ref={ (ref) => this.containerRef = ref }>
        {this.searchBar()}
        <div className='tool-list'>
          {this.renderToolList()}
        </div>
        {activeToolDialog}
      </div>
    );
  }
}

ToolPane.contextType = CGViewContext;

export default ToolPane;

