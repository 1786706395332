import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import ImageButton from '../presenters/ImageButton';
import { CGViewContext } from '../app/CGViewContext';
import './FormatBar.css';
// Connected
import { connect } from 'react-redux';
import store from '../app/store';
import { changeSquareLock } from '../actions/ui';

class FormatBar extends Component {

  static propTypes = {
    format:             PropTypes.oneOf(['linear', 'circular']).isRequired,
    squareLock:         PropTypes.bool,
    showLegend:         PropTypes.bool,
    showLabels:         PropTypes.bool,
    labelPlacement:     PropTypes.string,
    onSquareLockToggle: PropTypes.func,
  }

  static defaultProps = {
    format:          'circular',
    squareLock:      false,
    showLabels:      true,
    labelPlacement: 'default',
    showLegend:      true,
  }

  constructor(props) {
    super(props);

    this.onClickCircular = this.onClickCircular.bind(this);
    this.onClickLinear = this.onClickLinear.bind(this);
    this.onSquareLockToggle = this.onSquareLockToggle.bind(this);
    this.onInvertColors = this.onInvertColors.bind(this);
    this.onClickLabels = this.onClickLabels.bind(this);
    this.onClickLegend = this.onClickLegend.bind(this);
  }

  onClickCircular(cgv) {
    cgv.settings.update({ format: 'circular' });
    cgv.draw();
  }

  onClickLinear(cgv) {
    cgv.settings.update({ format: 'linear' });
    cgv.draw();
  }

  onSquareLockToggle(cgv) {
    // const squareLock = this.props.squareLock;
    cgv.draw();
  }

  onInvertColors(cgv) {
    cgv.invertColors();
  }

  onClickLabels(placement, cgv) {
    const { showLabels, labelPlacement } = this.props;
    if (!showLabels) {
      cgv.annotation.update({visible: true, labelPlacement: placement});
    } else if (labelPlacement != placement) {
      cgv.annotation.update({labelPlacement: placement});
    } else {
      cgv.annotation.update({visible: false});
    }
    cgv.draw();
  }

  onClickLegend(cgv) {
    const { showLegend } = this.props;
    cgv.legend.update({visible: !showLegend});
  }

  render() {
    const { format, squareLock, onClickSquareLockToggle, showLabels, labelPlacement, showLegend } = this.props;


    return (
      <CGViewContext.Consumer>
        {context => (
          <div className='FormatBar'>
            <ImageButton
              onClick={ () => this.onClickCircular(context.cgv) }
              imageName='circular'
              title='Circular Map [ / ]'
              disabled={false}
              active={format === 'circular'}
            / >
            <ImageButton
              onClick={ () => this.onClickLinear(context.cgv) }
              imageName='linear'
              title='Linear Map [ / ]'
              disabled={false}
              active={format === 'linear'}
            / >
            <ImageButton
              onClick={ () => this.onInvertColors(context.cgv) }
              imageName='invertColors'
              title='Invert Colors'
              disabled={false}
            / >
            <div>
            <ImageButton
              onClick={ () => this.onClickLegend(context.cgv) }
              imageName='legend'
              title='Show/Hide Legend'
              disabled={false}
              active={showLegend}
            / >
            <ImageButton
              onClick={ () => this.onClickLabels('default', context.cgv) }
              imageName='labelsDefault'
              title='Show/Hide Labels (Default)'
              disabled={false}
              active={showLabels && labelPlacement == 'default'}
            / >
            <ImageButton
              onClick={ () => this.onClickLabels('angled', context.cgv) }
              imageName='labelsAngled'
              title='Show/Hide Labels (Angled)'
              disabled={false}
              active={showLabels && labelPlacement == 'angled'}
            / >
            </div>
            <ImageButton
              onClick={ () => onClickSquareLockToggle(!squareLock) }
              imageName='squareLock'
              title='Lock/Unlock Map to Square Aspect Ratio'
              disabled={false}
              active={squareLock}
            / >
          </div>
        )}
      </CGViewContext.Consumer>
    );
  }

}

// Connected
const formatBarMapStateToProps = (state) => ({
  format: state.viewer.format,
  showLabels: state.annotation.visible,
  labelPlacement: state.annotation.labelPlacement,
  showLegend: state.legend.visible,
  squareLock: state.ui.squareLock
});
const formatBarMapDispatchToProps = (state) => ({
  onClickSquareLockToggle: squareLock => store.dispatch(changeSquareLock(squareLock)),
});
const ConnectedFormatBar = connect(formatBarMapStateToProps, formatBarMapDispatchToProps)(FormatBar);

// export default FormatBar;
export { FormatBar, ConnectedFormatBar };

