import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import { Select, Option } from '../presenters/Select';
import TextElement from '../presenters/TextElement';
import DataElement from '../presenters/DataElement';
import DataElementGroup from '../presenters/DataElementGroup';
import DataElementContainer from '../presenters/DataElementContainer';
import ButtonGroup from '../presenters/ButtonGroup';
import Button from '../presenters/Button';
import { CGViewContext } from '../app/CGViewContext';
import { ConnectedSelectLegend } from '../containers/SelectLegend';
import { ConnectedSelectTrack } from '../containers/SelectTrack';
import SelectGeneticCode from '../presenters/SelectGeneticCode';
import './SequenceSelector.css';
import * as helpers from '../support/Helpers';
import * as CGView from '../../CGView.js';

// Connected
import { connect } from 'react-redux';

// SequenceSelectionOptions
// - category
// - subcategory
// - keys
// - sequenceType
// - geneticCode
// - visibleOnly
// - options
// This requires the CGView object. We may want to move this to CGView.
// const CGV = CGView;

class SequenceSelectorOptions {


  constructor(options = {}) {
    this.category = options.category || 'features';
    this._subcategory = options.subcategory;
    this.keys = options.keys;
    this._selectedIDs = options.selectedIDs;
    this.sequenceType = options.sequenceType || 'dna';
  }

  get toString() {
    return 'SequenceSelectorOptions';
  }

  set category(value) {
    // if (CGV.validate(value, ['features', 'map', 'orfs'])) {
    if (CGView.utils.validate(value, ['features', 'map', 'orfs'])) {
      this._category = value;
    }
  }

  get category() {
    return this._category;
  }

  get subcategory() {
    return this._subcategory;
  }

  set keys(value) {
    let _keys = [];
    if (Array.isArray(value)) {
      _keys = value;
    } else if (value) {
      _keys = [value];
    }
    this._keys = _keys;
  }

  get keys() {
    return this._keys
  }

  get sequenceType() {
    return this._sequenceType;
  }

  set sequenceType(value) {
    // if (CGV.validate(value, ['dna', 'protein'])) {
    if (CGView.utils.validate(value, ['dna', 'protein'])) {
      this._sequenceType = value;
    }
  }

  get selectedIDs() {
    return this._selectedIDs;
  }

  // Return a representation of the seqOptions for saving with the input
  // Remove empty keys
  // Replace selectedIDs with a count
  toJSON() {
    // Category
    const json = {
      category: this.category,
    }
    // Add subcategory
    if (['features', 'map'].includes(this.category)) {
      json.subcategory = this.subcategory;
    }
    // Add Keys
    if (this.category === 'features' && ['legend', 'track', 'type', 'source'].includes(this.subcategory)) {
      json.keys = this.keys;
    }
    // Sequence Type
    json.sequenceType = this.sequenceType;
    // Add Genetic Code
    if (this.sequenceType === 'protein' || this.category === 'orfs') {
      json.geneticCode = this.geneticCode;
    }
    // Seleted IDs
    if (this.subcategory === 'selected') {
      json.selectedIDs = this.selectedIDs;
    }
    return json;
  }

}

class ExtractSequenceRanges {

  constructor(cgv, seqSelectOptions = {}) {
    this._cgv = cgv;
    this.seqSelectOptions = seqSelectOptions;
    this._items = this.extractItems();
    console.log(this.selectedIDs)
    console.log(seqSelectOptions)
  }

  get cgv() {
    return this._cgv;
  }

  get selectedIDs() {
    return this.seqSelectOptions.selectedIDs || [];
  }

  set seqSelectOptions(value = {}) {
    this._seqSelectOptions = (value.toString === 'SequenceSelectorOptions') ? value : new SequenceSelectorOptions(value);
  }

  get seqSelectOptions() {
    return this._seqSelectOptions;
  }

  get category() {
    return this.seqSelectOptions.category;
  }

  get subcategory() {
    return this.seqSelectOptions.subcategory;
  }

  get keys() {
    return this.seqSelectOptions.keys;
  }

  get sequenceType() {
    return this.seqSelectOptions.sequenceType;
  }

  get geneticCode() {
    return this.seqSelectOptions.geneticCode;
  }

  get items() {
    return this._items;
  }

  asFasta() {
    const regions = this.asRegions();
    let fasta = ''
    const defaultReadingFrame = 1
    const defaultGeneticCode = 11
    console.log(this.geneticCode)
    regions.forEach( r => {
      const revComp = (Number(r.strand) === -1);
      const seq = r.contig.forRange(r, revComp);
      // console.log(this.sequenceType)
      // console.log(this.seqSelectOptions)
      if (this.sequenceType === 'protein') {
        const readingFrame = r.codonStart || defaultReadingFrame;
        const geneticCode = this.geneticCode || r.geneticCode || this.cgv.geneticCode || defaultGeneticCode;
        const protein = this.cgv.codonTables.translate(seq, geneticCode, readingFrame);
        fasta += `>contig=${r.contig.name};start=${r.start};stop=${r.stop};strand=${r.strand};rf=${readingFrame};${r.name}\n`;
        fasta += protein;
      } else {
        fasta += `>contig=${r.contig.name};start=${r.start};stop=${r.stop};strand=${r.strand};${r.name}\n`;
        fasta += seq;
      }
      fasta += '\n';
    });
    return fasta;
  }

  // returns items (features and contigs) as regions
  // Each Region has the following attributes
  // - contig, start, stop, strand, name?
  asRegions() {
    const regions = [];
    this.items && this.items.forEach( i => {
      if (this.category === 'features') {
        regions.push({
          contig: i.contig,
          start: i.start,
          stop: i.stop,
          strand: i.strand,
          name: i.name,
          codonStart: i.codonStart,
        });
      } else if (this.category === 'map') {
        regions.push({
          contig: i,
          start: 1,
          stop: i.length,
          strand: 1,
          name: '',
        });
      }
    });
    console.log(regions)
    return regions;
  }

  get count() {
    return this.items && this.items.length;
  }

  extractItems() {
    switch(this.seqSelectOptions.category) {
      case 'features':
        return this.extractFeatures();
      case 'map':
        return this.extractMap();
    }
  }

  extractFeatures() {
    let features = [];
    const cgv = this.cgv;
    const keys = this.keys;
    switch(this.subcategory) {
      case 'all':
        features = cgv.features();
        break;
      case 'track':
        const tracks = cgv.objects(keys);
        features = tracks.map( t => t.features() ).flat();
        // features = keys.forEach( type => {
        //   const track = cgv.objects(k);
        //   features = features.concat(track.features());
        // });
        break;
      case 'legend':
        const legendItems = cgv.objects(keys);
        features = legendItems.map( t => t.features() ).flat();
        break;
      case 'type':
        features = keys.map( type => cgv.featuresByType(type) ).flat();
        break;
      case 'source':
        features = keys.map( type => cgv.featuresBySource(type) ).flat();
        break;
      case 'favorites':
        features = cgv.features().filter(f => f.favorite);
        break;
      case 'selected':
        console.log('selectedddddd', this.selectedIDs)
        features = cgv.objects(this.selectedIDs);
        break;
    }
    return features;
  }

  extractMap() {
    let contigs = [];
    const cgv = this.cgv;
    switch(this.subcategory) {
      case 'contigs':
        contigs = cgv.contigs();
        break;
      case 'sequence':
        contigs = [cgv.sequence.mapContig];
        break;
    }
    return contigs;
  }

}

class SequenceSelector extends React.Component {

  static propTypes = {
    cateogories: PropTypes.array,
    defaultCategory: PropTypes.oneOf(['features', 'map', 'orfs']),
    geneticCode: PropTypes.number,
    onChange: PropTypes.func,
    label: PropTypes.string,
  }

  static defaultProps = {
    label: 'Choose Sequences',
    categories: ['features', 'map', 'orfs'],
    onChange: () => {},
  }

  static categoryMap = {
    features: 'Features',
    map: 'Map',
    orfs: 'ORFs',
  };

  constructor(props) {
    super(props);

    // Set Initial Category
    const category = props.defaultCategory || props.categories[0];
    // Set Initial Subcategory
    const subcategoryMap = this[`${category}SubcategoryMap`];
    const subcategory = props.defaultSubcategory || subcategoryMap && Object.keys(subcategoryMap())[0];

    this.state = {
      seqOptions: {
        category,
        subcategory,
        keys: [],
        geneticCode: props.geneticCode,
        onlyVisible: false,
        sequenceType: 'dna',
        selectedIDs: [],
      }
    };
  }

  componentDidMount() {
    const { seqOptions } = this.state;
    this.updateSequenceOptions({attribute: 'category', value: seqOptions.category});
  }

  onChange({attribute, value}) {
    this.updateSequenceOptions({attribute, value});
  }

  // Whenever a category/subcategory changes, the subcategories/keys need to be updated
  updateSequenceOptions({attribute, value}) {
    const { seqOptions } = this.state;
    const cgv = this.context.cgv;

    let { category, subcategory, keys, selectedIDs } = seqOptions;
    if (attribute === 'category') {
      category = value;
      const subcategoryMap = this[`${category}SubcategoryMap`];
      subcategory = subcategoryMap && Object.keys(subcategoryMap())[0];
    } else if (attribute === 'subcategory') {
      subcategory = value;
    } else if (attribute === 'keys') {
      keys = [value];
    }
    if (['category', 'subcategory'].includes(attribute)) {
      // Update keys
      if (subcategory === 'track') {
        const track = cgv.tracks(1);
        keys = track && [track.cgvID];
      } else if (subcategory === 'legend') {
        const legendItem = cgv.legend.items(1);
        keys = legendItem && [legendItem.cgvID];
      } else if (subcategory === 'type') {
        const type = cgv.featureTypes(1);
        keys = type && [type];
      } else if (subcategory === 'source') {
        const source = cgv.sources(1);
        keys = source && [source];
      }
      // Update selected IDs
      if (subcategory === 'selected') {
        const featurePane = this.context.sidebarRef.getPane('features');
        selectedIDs = featurePane.selectedIDs();
      } else {
        selectedIDs = []
      }
    }

    this.setState((state) => ({
      seqOptions: {
        ...state.seqOptions,
        [attribute]: value,
        category, subcategory, keys, selectedIDs
      },
    }), () => {
      const newSeqOptions = new SequenceSelectorOptions(this.state.seqOptions);
      // this.props.onChange(this.state.seqOptions)
      this.props.onChange(newSeqOptions.toJSON());
    });
  }

  renderSelect({label, options, optionMap, selectedValue, attribute}) {
    let content;
    // No options
    if (!options || options.length === 0) { return; }

    // Use provide options map or create one
    let optMap = {}
    if (optionMap) {
      optMap = optionMap;
    } else {
      options.forEach( i => optMap[i] = i );
    }

    if (options.length === 1) {
      content = <TextElement>{optMap[options[0]]}</TextElement>;
    } else {
      const selectOptions = options.map( s => <Option value={s} key={s}>{optMap[s]}</Option> );
      content = (
        <Select value={selectedValue}
          onChange={ (value) => { this.onChange({value, attribute}) }}>
          {selectOptions}
        </Select>
      );
    }
    return <DataElement label={label}>{content}</DataElement>;
  }

  renderCategory() {
    const { categories } = this.props;
    const { seqOptions } = this.state;
    return this.renderSelect({
      label: 'Select',
      options: categories,
      optionMap: SequenceSelector.categoryMap,
      selectedValue: seqOptions.category,
      attribute: 'category',
    });
  }

  renderSubcategory() {
    const { seqOptions } = this.state;
    const category = seqOptions.category;
    const subcategoryMap = this[`${category}SubcategoryMap`] && this[`${category}SubcategoryMap`]();
    const subcategories = Object.keys(subcategoryMap);
    return this.renderSelect({
      label: 'By',
      options: subcategories,
      optionMap: subcategoryMap,
      selectedValue: seqOptions.subcategory,
      attribute: 'subcategory',
    });
  }

  featuresSubcategoryMap() {
    return {
      all: 'All',
      track: 'Track',
      type: 'Type',
      legend: 'Legend',
      source: 'Source',
      selected: 'Selected',
      favorites: 'Favorites',
    };
  }

  mapSubcategoryMap() {
    return {
      contigs: 'Contigs',
      sequence: 'Map Sequence',
    };
  }


  // Keys only appear for features
  renderKeys() {
    const { seqOptions } = this.state;
    const category = seqOptions.category;
    const seqKey = Array.isArray(seqOptions.keys) ? seqOptions.keys[0] : undefined;
    const subcategory = seqOptions.subcategory;
    if (category !== 'features') { return; }
      console.log(seqKey)

    const connectedSelects = {
      legend: ConnectedSelectLegend,
      track: ConnectedSelectTrack,
    }

    if (['legend', 'track'].includes(subcategory)) {
      const ConnectedSelect = connectedSelects[subcategory];
      return (
        <DataElement label={helpers.capitalize(subcategory)}>
          <ConnectedSelect
            title=''
            value={seqKey}
            allowNewItem={false}
            onChange={(value) => this.onChange({value: value.cgvID, attribute: 'keys'})}
            help=''
           />
        </DataElement>
      );
    } else if (subcategory === 'type') {
      const featureTypes = this.context.cgv.featureTypes();
      return this.renderSelect({
        label: 'Type',
        selectedValue: seqKey || featureTypes[0],
        options: featureTypes,
        attribute: 'keys',
      });
    } else if (subcategory === 'source') {
      const sources = this.context.cgv.sources();
      return this.renderSelect({
        label: 'Source',
        selectedValue: seqKey || sources[0],
        options: sources,
        attribute: 'keys',
      });
    }
  }

  renderSequenceType() {
    const { seqOptions } = this.state;
    const sequenceType = seqOptions.sequenceType;
    return (
      <DataElement label='Sequence Type'>
        <ButtonGroup>
          <Button
            width={72}
            active={sequenceType === 'dna'}
            onClick={() => this.onChange({value: 'dna', attribute: 'sequenceType'})}
          >DNA</Button>
          <Button
            width={72}
            active={sequenceType === 'protein'}
            onClick={() => this.onChange({value: 'protein', attribute: 'sequenceType'})}
          >Protein</Button>
        </ButtonGroup>
      </DataElement>
    );
  }

  renderGeneticCode() {
    const { seqOptions } = this.state;
    const sequenceType = seqOptions.sequenceType;
    if (sequenceType === 'protein' || seqOptions.category === 'orfs') {
      return (
        <div className='genetic-code open'>
          <SelectGeneticCode title='Genetic Code' value={seqOptions.geneticCode}
            onChange={(value) => this.onChange({value, attribute: 'geneticCode'})} />
        </div>
      );
    } else {
      return (
        <div className='genetic-code'>
          <SelectGeneticCode title='Genetic Code' value={seqOptions.geneticCode} />
        </div>
      );
      // return <div className='genetic-code' />
    }
  }

  getCount() {
    const { seqOptions } = this.state;
    const seqItems = new ExtractSequenceRanges(this.context.cgv, seqOptions);
    return seqItems.count;
  }

  renderSummary() {
    const { seqOptions } = this.state;
    const sequenceType = seqOptions.sequenceType;
    const shortTypeMap ={ dna: 'DNA', protein: 'Protein', };

    const count = this.getCount();
    const displayCount = (count === undefined) && '??' || helpers.commaNumber(count);

    return (
      <DataElement label={`Count (${shortTypeMap[sequenceType]})`}>
        <TextElement>
          {displayCount}
        </TextElement>
      </DataElement>
    );
  }

  render() {
    const { help, label } = this.props;
    return (
      <div className='SequenceSelector'>
        <DataElementContainer label={label} maxHeight='185px'>
          <DataElementGroup>
            {this.renderCategory()}
            {this.renderSubcategory()}
            {this.renderKeys()}
          </DataElementGroup>
          <DataElementGroup>
            {this.renderSequenceType()}
            {this.renderSummary()}
          </DataElementGroup>
          {this.renderGeneticCode()}
        </DataElementContainer>
      </div>
    );

  }

}

SequenceSelector.contextType = CGViewContext;

// Connected
const sequenceSelectorMapStateToProps = (state) => ({ items: state.legendItems });
const ConnectedSequenceSelector = connect(sequenceSelectorMapStateToProps)(SequenceSelector);

// export default SequenceSelector;
export { SequenceSelector, ConnectedSequenceSelector, ExtractSequenceRanges };

