import React from 'react';
import DataElement from '../presenters/DataElement';
import { Select, Option } from '../presenters/Select';
import TextInput from '../presenters/TextInput';
import NumericInput from '../presenters/NumericInput';
import Slider from '../presenters/Slider';
import Switch from '../presenters/Switch';
import FileInput from '../presenters/FileInput';
import { SequenceSelector } from '../containers/SequenceSelector';
import { ConnectedSelectTrack } from '../containers/SelectTrack';
import { ConnectedSelectLegend } from '../containers/SelectLegend';
import * as helpers from '../support/Helpers';

const renderableTypes = ['select', 'textInput', 'numberInput', 'selectTrack', 'selectLegend', 'file', 'slider', 'switch', 'sequenceSelector'];

class Input {

  constructor(id, data, toolID) {
    this.id = id;
    this.toolID = toolID;
    for (const key of Object.keys(data)) {
      this[key] = data[key];
    }
    // The following properties are removed to prevent any conflicts: id, type, target, defaultSettingsManager
    // const { id: myID, type, target, defaultSettingsManager, ...props } = data;
    const { id: myID, type, target, defaultSettingsManager, autoSave, ...props } = data;
    this.adjustAutoSave();
    this.autoSave
    this._props = props;
  }

  // Returns the props to use for a component.
  // These props are the kwy/value pairs for an input from the tool.yaml file
  get props() {
    return this._props || {};
  }

  adjustAutoSave() {
    // For files, legends, tracks, autoSave is always false for now
    if (['selectTrack', 'selectLegend', 'file'].includes(this.type)) {
      this.autoSave = false;
    }
    if (this.autoSave === undefined) {
      this.autoSave = true;
    }
  }

  displayName(props={}) {
    // Name source order preference
    // - props.label: provided by custom Dialog input props
    // - this.name: comes from tool.yaml for input
    // - this.id: the key/id of this input from the tool.yaml
    return props.label || this.name || helpers.capitalize(this.id);
  }

  // Return the default value for the input from:
  // - LocalStorage or tool.yaml file
  defaultValue() {
    // if (this.defaultSettingsManager) {
    if (this.autoSave) {
      const storageKey = `dsm.${this.toolID}.${this.target}`;
      // console.log(storageKey)
      let savedOptions = localStorage.getItem(storageKey);
      if (savedOptions !== null) {
        savedOptions = JSON.parse(savedOptions);
        const savedDefualt = savedOptions[this.id];
        if (savedDefualt !== undefined) {
          return savedDefualt;
        }
      }
    }
    return this.default;
  }

  displayHelp(props={}) {
    // Help source order preference
    // - props.help: provided by custom Dialog input props
    // - this.help: help key for input from tool.yaml
    return props.help || this.help;
  }

  displayAlign(props={}) {
    // Align source order preference
    // - props.align: provided by custom Dialog input props
    // - this.align: align key for input from tool.yaml
    return props.align || this.align;
  }

  renderable() {
    return renderableTypes.includes(this.type);
  }

  renderSelect(onChange, props={}) {
    const propValues = props.values || this.values;
    const disabledValues = props.disabledValues || this.disabledValues || [];
    // const values = helpers.convertToObject(this.values);
    const values = helpers.convertToObject(propValues);
    // This will use the array order if this.values was an array
    // const keys = (Array.isArray(this.values)) ? this.values.map( v => v.toString()) : Object.keys(values);
    const keys = (Array.isArray(propValues)) ? propValues.map( v => v.toString()) : Object.keys(values);
    const options = keys.map( k => <Option key={k} value={k} disabled={disabledValues.includes(k)}>{values[k]}</Option> );
    // Remove disabledValues from props
    delete props.disabledValues;
      {/* <DataElement label={this.displayName()} help={this.help}> */}
    return (
      <DataElement label={this.displayName(props)} help={this.displayHelp(props)} align={this.displayAlign(props)}>
        <Select
          defaultValue={this.defaultValue()}
          {...this.props}
          onChange={ (value) => onChange({attribute: this.id, value})}
          {...props}
        >
          {options}
        </Select>
      </DataElement>
    )
  }

  renderSelectTrack(onChange, props={}) {
    return (
      <ConnectedSelectTrack
        callInitialOnChange
        title={this.displayName(props)}
        defaultItemID={this.default}
        {...this.props}
        onChange={(value) => onChange({attribute: this.id, value})}
        {...props}
      />
    )
  }

  renderSelectLegend(onChange, props={}) {
    return (
      <ConnectedSelectLegend
        callInitialOnChange
        title={this.displayName(props)}
        defaultItemID={this.default}
        {...this.props}
        onChange={(value) => onChange({attribute: this.id, value})}
        {...props}
      />
    )
  }

  renderTextInput(onChange, props={}) {
    return (
      <DataElement label={this.displayName(props)} help={this.displayHelp(props)}>
        <TextInput 
          callInitialOnChange
          defaultValue={this.defaultValue()}
          {...this.props}
          onChange={(value) => onChange({attribute: this.id, value})}
          {...props}
        />
      </DataElement>
    )
  }

  // REMOVE the key causes not to be there the next time the dialog is openned
  // Returns the value found in provided props or this.props for the key.
  // The key is also removed frrom both props and this.props.
  // removeKey(key, props) {
  //   let value;
  //   if (props[key] !== undefined) {
  //     value = props[key];
  //   } else if (this.props[key] !== undefined) {
  //     value = this.props[key];
  //   }
  //   delete props[key];
  //   delete this.props[key];
  //   return value;
  // }

  renderNumberInput(onChange, props={}) {
    // console.log(this.props)
    // const defaultValue = this.props['default']
    //       defaultValue={defaultValue}
    return (
      <DataElement label={this.displayName(props)} help={this.displayHelp(props)}>
        <NumericInput 
          {...this.props}
          onChange={(value) => onChange({attribute: this.id, value})}
          {...props}
          defaultValue={this.defaultValue()}
        />
      </DataElement>
    )
  }

  renderSlider(onChange, props={}) {
    return (
      <DataElement label={this.displayName(props)} help={this.displayHelp(props)}>
        <Slider 
          defaultValue={this.defaultValue()}
          {...this.props}
          onChange={(value) => onChange({attribute: this.id, value})}
          {...props}
        />
      </DataElement>
    )
  }

  renderSwitch(onChange, props={}) {
    return (
      <DataElement label={this.displayName(props)} help={this.displayHelp(props)} align={this.displayAlign(props)}>
        <Switch 
          defaultValue={this.defaultValue()}
          {...this.props}
          onChange={(value) => onChange({attribute: this.id, value})}
          {...props}
        />
      </DataElement>
    )
  }

  // TODO: use a prop to determine whether to use includeText
  // TODO: help and label. How should help be handled. currrently by FileInput
  renderFile(onChange, props={}) {
    return (
      <DataElement label={this.displayName(props)}>
        <FileInput
          {...this.props}
          onChange={(pfile) => {onChange({attribute: this.id, value: pfile})}}
          {...props}
        />
      </DataElement>
    )
  }

  renderSequenceSelector(onChange, props={}) {
    //<DataElement label={this.displayName(props)} help={this.displayHelp(props)}>
    return (
      <SequenceSelector
        label={this.displayName(props)}
        help={this.displayHelp(props)}
        {...this.props}
        onChange={(value) => onChange({attribute: this.id, value})}
        {...props}
      />
    )
  }

  render(onChange, props) {
    const renderMethod = `render${helpers.capitalize(this.type)}`;
    // console.log(renderMethod)
    if (typeof this[renderMethod] === 'function') {
      const display = this[renderMethod](onChange, props);
      return <div key={this.id}>{display}</div>
    }
  }

}

export default Input;

