import React from 'react';
import ReportCardBase from '../../../cards/ReportCardBase';

class ReportCard extends ReportCardBase {

  renderData(mapData) {
    // False is passed through so that so map data table is shown
    // return this.renderDefaultReport(false);
    const msg = "CGView Builder takes your provided genome sequence and generates the JSON used by CGView.js to view your map."
    return (
      <div className='report-card'>
        { this.renderReportHeader() }
        { this.renderAlert(msg) }
        { this.renderReportFailed() }
        { this.renderFeaturedFilesTable() }
        { this.renderHelpfulLinks() }
      </div>
    );
  }

}

export default ReportCard;

