import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CGViewContext } from '../app/CGViewContext';
import '../support/CommonStyles.css';
import './ListPane.css';
import './TrackPane.css';
import VirtualList from '../presenters/VirtualList';
import DataElement from '../presenters/DataElement';
import DataElementGroup from '../presenters/DataElementGroup';
import ImageButton from '../presenters/ImageButton';
import TextInput from '../presenters/TextInput';
import TextElement from '../presenters/TextElement';
import { Select, Option } from '../presenters/Select';
import ConnectedTrackDataSelector from '../containers/TrackDataSelector';
import VisibilityButton from '../presenters/VisibilityButton';
import Slider from '../presenters/Slider';
import TrackDeleteDialog from './dialogs/TrackDeleteDialog';
import * as helpers from '../support/Helpers';
import '../support/CommonStyles.css';
// Connected
import { connect } from 'react-redux';

class TrackPane extends React.Component {

  static propTypes = {
    tracks: PropTypes.shape({
      ids:  PropTypes.array,
      byID: PropTypes.object,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      deleteID: undefined,
    };

    this.onSortEnd = this.onSortEnd.bind(this);
    this.listItemID = this.listItemID.bind(this);
    this.listItemMainRenderer = this.listItemMainRenderer.bind(this);
    this.listItemInfoRenderer = this.listItemInfoRenderer.bind(this);
    this.onRowMouseOver = this.onRowMouseOver.bind(this);
    this.onRowMouseOut = this.onRowMouseOut.bind(this);
    this.setListRef = this.setListRef.bind(this);
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    const cgv = this.context.cgv;
    cgv.moveTrack(oldIndex, newIndex);
    cgv.draw();
  };

  listItemID(index) {
    const track = helpers.itemForIndex(this.props.tracks, index);
    return track.cgvID
  }

  openInfoFor(id) {
    const index = this.props.tracks.ids.indexOf(id);
    this.listRef.scrollToRow(index);
    this.listRef.openInfoFor(id);
    this.listRef.recomputeRowHeights(index);
  }

  onClickVisibilityToggle(index) {
    const track = helpers.itemForIndex(this.props.tracks, index);
    const visible = !track.visible;
    this.onTrackChange({cgvID: track.cgvID, value: visible, attribute: 'visible'})
    this.listRef.recomputeRowHeights(index);
  }

  listItemMainRenderer(index) {
    const track = helpers.itemForIndex(this.props.tracks, index);
    const titleClass = classNames('list-item-title', 'fade-in', {fade: !track.visible});
    const progressClass = classNames('track-progress', {
      'track-progress-complete': (track.loadProgress === 100),
      'fade-out': (track.loadProgress === 100),
      'track-no-progress': !track.loadProgress
    });
    return (
      <div className='list-item-content'>
        <div className='track-title-container'>
          <div className={titleClass}>{track.name}</div>
          <div className={progressClass}>{track.loadProgress}%</div>
        </div>
        <div className='list-item-options'>
          <VisibilityButton visible={track.visible} onClick={ () => this.onClickVisibilityToggle(index) } />
        </div>
      </div>
    );
  }

  onTrackChange({cgvID, attribute, value, redraw=true}) {
    const cgv = this.context.cgv;
    const track = cgv.objects(cgvID);
    cgv.updateTracks(track, {[attribute]: value});
    redraw && cgv.draw();
  }

  onClickViewFeatures(track) {
    const sidebar = this.context.sidebarRef;
    const featurePane = sidebar.selectPane('features');
    // FIXME:
    featurePane.searchWithString(`"${track.name}":track`);
  }

  listItemInfoRenderer(index) {
    // console.log('ANIMATING?')
    const track = helpers.itemForIndex(this.props.tracks, index);
    const cgvID = track.cgvID;
    let dataKeys = track.dataKeys || [];
    dataKeys = Array.isArray(dataKeys) ? dataKeys : [dataKeys];
    // const selectedSources = Array.isArray(extract) ? extract : [extract]
    // FIXME: should be from redux
    const allSources = this.context.cgv.sources().filter( s => s !== '');
    const isFeatureTrack = (track.dataType === 'feature');

    // NOTE: This removes the 'Both' option when separating features by none
    // and sets the display value as 'outside'. Internally, it's still saved
    // as 'both'
    const separateFeaturesByNone = (track.separateFeaturesBy === 'none');
    const trackPosition = (separateFeaturesByNone && track.position === 'both') ? 'outside' : track.position;

    const numberTitle = isFeatureTrack ? 'Features' : 'Points';

    const separateFeaturesByElement = isFeatureTrack && (
      <DataElement label='Separate Features By' helpPath='help:sidebar:display:tracks:separate_features_by'>
        <Select value={track.separateFeaturesBy}
          onChange={(value) => this.onTrackChange({cgvID, value, attribute: 'separateFeaturesBy'})}>
          <Option value="none">None</Option>
          <Option value="strand">Strand</Option>
          <Option value="readingFrame">Reading Frame</Option>
        </Select>
      </DataElement>
    );

    return (
      <div>
        <DataElement label='Name' helpPath='help:sidebar:display:tracks:name'>
          <TextInput value={track.name}
            onChange={(value) => this.onTrackChange({cgvID, value, attribute: 'name', redraw: false})}/>
        </DataElement>
        <DataElementGroup>
          <DataElement label='Position' helpPath='help:sidebar:display:tracks:position'>
            <Select value={trackPosition}
              onChange={(value) => this.onTrackChange({cgvID, value, attribute: 'position'})}>
              { isFeatureTrack && !separateFeaturesByNone && <Option value="both">Both</Option> }
              <Option value="inside">Inside</Option>
              <Option value="outside">Outside</Option>
            </Select>
          </DataElement>
          {separateFeaturesByElement}
        </DataElementGroup>
        <DataElement label='Size Ratio' helpPath='help:sidebar:display:tracks:size_ratio'>
          <Slider min={0.1} max={4} step={0.1} value={track.thicknessRatio}
            onChange={(value) => this.onTrackChange({cgvID, value, attribute: 'thicknessRatio'})} />
        </DataElement>

        <ConnectedTrackDataSelector cgvID={cgvID}
          onChange={(change) => this.onTrackChange({cgvID, value: change.value, attribute: change.attribute})} />

        <DataElement label={`Number of ${numberTitle}`} helpPath='help:sidebar:display:tracks:number'>
          <TextElement clickable={isFeatureTrack} onClick={isFeatureTrack ? (() => this.onClickViewFeatures(track)) : undefined} >
            { track.itemCount }
          </TextElement>
        </DataElement>
        <div className='action-buttons'> 
          <ImageButton imageName='delete' text='Delete Track' width={110}
          title='Delete Track' onClick={ () => this.setState( {deleteID: track.cgvID}) } />
        </div>
      </div>
    );
  }

  renderDeleteDialog() {
    const { deleteID } = this.state;
    if (deleteID) {
      return (
        <TrackDeleteDialog
          trackID={deleteID}
          onClose={() => this.setState({deleteID: undefined})}
        />
      );
    }
  }

  onAddTrack() {
    const cgv = this.context.cgv;
    const tracks = cgv.addTracks({
      name: 'New Track',
      separateFeaturesBy: 'strand',
      position: 'both',
      dataType: 'feature',
      dataMethod: 'tag',
    });
    cgv.draw();
  }

  onTrackListing() {
    const sidebar = this.context.sidebarRef;
    const toolPane = sidebar.getPane('tools');
    toolPane.clickOpenTool({id: 'track_lister'});
  }

  onRowMouseOver(index) {
    const cgv = this.context.cgv;
    const trackData = helpers.itemForIndex(this.props.tracks, index);
    const track = cgv.objects(trackData.cgvID);
    if (track) {
      track.highlight();
    }
  }

  onRowMouseOut() {
    const cgv = this.context.cgv;
    cgv.clear('background');
    cgv.clear('ui');
  }

  setListRef(ref) {
    this.listRef = ref;
  }

  render() {
    const tracks = helpers.itemsAsArray(this.props.tracks);

    // console.log('RENDER TRACKPANE')
    // console.log(tracks.map(t => t.name))

          // className='TrackPane'
    return (
      <div style={{width: '100%', height: '100%'}} className='TrackPane'>
        <VirtualList
          itemCount={tracks.length}
          items={tracks}
          dataName={'Tracks'}
          sortable={true}
          onSortEnd={this.onSortEnd}
          listItemMainRenderer={this.listItemMainRenderer}
          listItemInfoRenderer={this.listItemInfoRenderer}
          listItemID={this.listItemID}
          onRowMouseOver={this.onRowMouseOver}
          onRowMouseOut={this.onRowMouseOut}
          setRef={this.setListRef}
          addAction={(ids)=>{this.onAddTrack()}}
          otherActions={[{
            title: 'Track List Caption...',
            imageName: 'trackListing',
            position: 'end',
            onClick: () => {this.onTrackListing()}
          }]}
        />
        {this.renderDeleteDialog()}
      </div>
    );
  }
}

TrackPane.contextType = CGViewContext;

//Connected
const trackPaneMapStateToProps = (state) => ({ tracks: state.tracks });
// const trackPaneMapStateToProps = (state) => ({ trackIDs: state.tracks.ids, tracksByID: state.tracks.byID });
const ConnectedTrackPane = connect(trackPaneMapStateToProps, null, null, {forwardRef: true})(TrackPane);

// export default TrackPane;
export { TrackPane, ConnectedTrackPane };

