export default function add({inputs}) {

  const mapData = {
    tracks: [{
      name: inputs.trackName,
      position: 'both',
      separateFeaturesBy: 'readingFrame',
      dataType: 'feature',
      dataMethod: 'sequence',
      dataKeys: 'orfs',
      dataOptions: { minORFLength: inputs.minSize },
    }],
  }

  return {ok: true, mapData};
}

