import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ImageButton.css';
import iconDelete from '../images/icon-trash.png';
import iconEdit from '../images/icon-pencil.png';
import iconAdd from '../images/icon-add.png';
import iconAlert from '../images/icon-alert.png';
import iconExpand from '../images/icon-expand.png';
import fbCircular from '../images/fb-circular.png';
import fbLinear from '../images/fb-linear.png';
import fbInvertColors from '../images/fb-invert-colors.png';
import fbSquareLock from '../images/fb-square-lock.png';
import fbLabelsDefault from '../images/fb-labels-default.svg';
import fbLabelsAngled from '../images/fb-labels-angled.svg';
import fbLegend from '../images/fb-legend.svg';
import fbBookmark from '../images/fb-bookmark.svg';
import cbResetCircular from '../images/cb-reset-circular.png';
import cbResetLinear from '../images/cb-reset-linear.png';
import cbMoveCounterClockwise from '../images/cb-move-counter-clockwise.png';
import cbMoveClockwise from '../images/cb-move-clockwise.png';
import cbMoveLeft from '../images/cb-move-left.png';
import cbMoveRight from '../images/cb-move-right.png';
import cbZoomIn from '../images/cb-zoom-in.png';
import cbZoomOut from '../images/cb-zoom-out.png';
import sidebarClose from '../images/sidebar-close.png';
import sidebarOpen from '../images/sidebar-open.png';
import iconChevron from '../images/icon-chevron.png';
import iconVisibleOn from '../images/icon-visible-on.png';
import iconVisibleOff from '../images/icon-visible-off.png';
import iconGoto from '../images/icon-goto.png';
import iconDecorationArrow from '../images/icon-decoration-arrow.png';
import iconDecorationArc from '../images/icon-decoration-arc.png';
import iconDecorationScore from '../images/icon-decoration-score.png';
import iconDecorationNone from '../images/icon-decoration-none.png';
import iconStarFull from '../images/icon-star-full.png';
import iconRefresh from '../images/icon-refresh.png';
import iconDownload from '../images/icon-download.png';
import iconDownloadSmall from '../images/icon-download-small.png';
import iconViewerFull from '../images/icon-viewer-full';
import iconViewerRight from '../images/icon-viewer-right';
import iconViewerBottom from '../images/icon-viewer-bottom';
import iconSettings from '../images/icon-settings';
import iconBackward from '../images/icon-backward.png';
import iconForward from '../images/icon-forward.png';
import iconUp from '../images/icon-up.png';
import iconDown from '../images/icon-down.png';
import iconFile from '../images/icon-file.png';
import iconLog from '../images/icon-log.png';
import iconDataCard from '../images/icon-data-card.png';
import iconTrackListing from '../images/icon-track-listing.svg';
import iconCopy from '../images/icon-copy.svg';
import Tooltip from './Tooltip';

//PROPTYPES AND DEFAULTS ARE AT THE BOTTOM OF THIS FILE

// function ImageButton(props) {
const ImageButton = React.forwardRef((props, ref) => {
  const {
    className,
    onClick,
    onMouseDown,
    image,
    imageName,
    title,
    width,
    text, 
    size,
    type,
    disabled,
    active,
    tip,
    inline,
    tipPosition = 'top',
  } = props;

  const imageNames = {
    delete: iconDelete,
    add: iconAdd,
    edit: iconEdit,
    alert: iconAlert,
    expand: iconExpand,
    circular: fbCircular,
    linear: fbLinear,
    invertColors: fbInvertColors,
    squareLock: fbSquareLock,
    labelsDefault: fbLabelsDefault,
    labelsAngled: fbLabelsAngled,
    legend: fbLegend,
    bookmarkWithText: fbBookmark,
    resetCircular: cbResetCircular,
    resetLinear: cbResetLinear,
    moveClockwise: cbMoveClockwise,
    moveCounterClockwise: cbMoveCounterClockwise,
    moveLeft: cbMoveLeft,
    moveRight: cbMoveRight,
    zoomIn: cbZoomIn,
    zoomOut: cbZoomOut,
    sidebarOpen,
    sidebarClose,
    chevron: iconChevron,
    visibleOn: iconVisibleOn,
    visibleOff: iconVisibleOff,
    goto: iconGoto,
    decorationArrow: iconDecorationArrow,
    decorationArc: iconDecorationArc,
    decorationScore: iconDecorationScore,
    decorationNone: iconDecorationNone,
    starFull: iconStarFull,
    refresh: iconRefresh,
    download: iconDownload,
    downloadSmall: iconDownloadSmall,
    viewerFull: iconViewerFull,
    viewerRight: iconViewerRight,
    viewerBottom: iconViewerBottom,
    settings: iconSettings,
    backward: iconBackward,
    forward: iconForward,
    up: iconUp,
    down: iconDown,
    file: iconFile,
    log: iconLog,
    dataCard: iconDataCard,
    trackListing: iconTrackListing,
    copy: iconCopy,
  }

  const clickHandler = disabled ? () => {} : onClick;
  const mouseDownHandler = disabled ? () => {} : onMouseDown;

  const buttonClass = classNames(
    'ImageButton', className, size, `type-${type}`,
    {disabled, active, 'text-included': text}
  );
  // console.log(buttonClass);

  // Adjust width
  const style = {};
  if (text && width) {
    style.width = width;
    style.justifyContent = 'start';
  } else if (width) {
    style.width = width;
  }
  // Adjust inline style
  if (inline) {
    style.display = 'inline-flex'
  }

  // Image src will come from image or imageName
  // imageName is predefined icons (e.g. 'delete')
  const imageSrc = imageName ? imageNames[imageName] : image;

  return (
    <div className={buttonClass}
      onClick={(e) => {e.stopPropagation();clickHandler();}}
      onMouseDown={mouseDownHandler}
      onDragStart={(e) => { e.preventDefault(); }}
      data-tip={tip}
      data-place={tipPosition}
      data-for='ImageButton'
      style={style}
      ref={ref}
    >
      <img src={imageSrc} alt={title} title={title} />
      {text}
      <Tooltip id='ImageButton' />
    </div>
  )
});

ImageButton.propTypes = {
  className:   PropTypes.string,
  onClick:     PropTypes.func,
  onMouseDown: PropTypes.func,
  image:       PropTypes.string,
  imageName:   PropTypes.string,
  text:        PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title:       PropTypes.string,
  type:        PropTypes.oneOf(['plain', 'button']),
  size:        PropTypes.oneOf(['default', 'small', 'large']),
  disabled:    PropTypes.bool,
  active:      PropTypes.bool,
  tip:         PropTypes.string,
  tipPosition: PropTypes.string,
}

ImageButton.defaultProps = {
  title:    '',
  onClick:  () => {},
  size:     'default',
  type:    'button',
  disabled: false,
  active:   false,
  tipPosition: 'top',
}

export default ImageButton;

