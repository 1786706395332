import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from './Button';
// FIXME: we need our own checkbox component
// import { Checkbox } from 'antd';
import './DefaultSettingsManager.css';

DefaultSettingsManager.propTypes = {
  className:   PropTypes.string,
  tool:        PropTypes.object,
  componentID: PropTypes.string,
  toolOptions: PropTypes.object,
  resetFunc:   PropTypes.func,
  testFunc:    PropTypes.func,
  // resetActive: PropTypes.bool,
  // storageKey:  PropTypes.string,
}

DefaultSettingsManager.defaultProps = {
}

function DefaultSettingsManager(props) {
  const {
    className,
    tool,
    componentID,
    toolOptions,
    resetFunc,
    testFunc,
  } = props;

  // const storageKey = `dsm.${tool.id}.${componentID}`;

  function resetDefaults() {
    resetFunc && resetFunc();
  }

  // Returns true if any options are not the defualt value
  function resetActive() {
    const inputs = tool.inputsForTarget(componentID);
    const options = {};
    let isActive = false;
    console.log(inputs)
    for (const input of inputs) {
      if (!input.autoSave) { continue; }
      if (toolOptions[input.id] !== input.default) {
        options[input.id] = toolOptions[input.id];
        isActive = true;
      }
    }
    return isActive;
  }

  // function onChangeStatus(value) {
  //   // console.log('status', value);
  //   // console.log('key', storageKey);
  //   if (value && storageKey) {
  //     localStorage.setItem(storageKey, '{}');
  //   } else {
  //     localStorage.removeItem(storageKey);
  //   }
  //   // setStatusOn(value);
  // }

  const dsmClass = classNames(
    'DefaultSettingsManager', className
  );

  function inputsUseDefaultSettingsManager() {
    const inputs = tool.inputsForTarget(componentID);
    // return inputs.some( input => input.defaultSettingsManager );
    return inputs.some( input => input.autoSave );
  }

  // function saveNewDefaultsToLocalStorage() {
  //     const inputs = tool.inputsForTarget(this.component.id);
  //     const options = {};
  //     for (const input of inputs) {
  //       if (input.defaultSettingsManager) {
  //         if (toolOptions[input.id] !== input.default) {
  //           options[input.id] = toolOptions[input.id];
  //         }
  //       }
  //     localStorage.setItem(storageKey, JSON.stringify(options));
  //   }
  // }

  if (!inputsUseDefaultSettingsManager()) { return null; }

      // <div>
      //   <Checkbox checked={statusOn} onChange={ (e) => onChangeStatus(e.target.checked) }>Remember options for next time</Checkbox>
      // </div>
  const testButton = testFunc && (
      <Button
        className='dms-btn-reset'
        height={32}
        width='50px'
        onClick={ testFunc }
        title='Test saving changes to LocalStorage'
      >Test</Button>
  );
  return (
    <div className={dsmClass}>
      <Button
        className='dms-btn-reset'
        height={32}
        disabled={!resetActive()}
        width='94px'
        onClick={ resetDefaults }
        title='Reset all settings to their default values'
      >
        Reset Options
      </Button>
      {testButton}
    </div>
  )
}

export default DefaultSettingsManager;

