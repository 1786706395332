// NOTES:
// - A DataElement is a single element (e.g text input, select, swtich, etc) with a label and optional help
// - A DataElementGroup is a row of of DataElements, filled to take the available space (by width)
// - A DataElementContainer contains one or more DataElements and/or DataElementGroups

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './DataElementGroup.css';

DataElementGroup.propTypes = {
  className: PropTypes.string,
  children:  PropTypes.node.isRequired,
  disabled:  PropTypes.bool,
}

DataElementGroup.defaultProps = {
  disabled: false,
}

function DataElementGroup(props) {
  const {
    className,
    children,
    help,
    // disabled=false,
  } = props;

  const elementClass = classNames(
    'DataElementGroup', className
  );

  const renderHelp = help ? <div className='help-text'>{help}</div> : '';

  return (
    <div className={elementClass}>
      <div className='children-group'>
        {children}
      </div>
      {renderHelp}
    </div>
  )
}

export default DataElementGroup;

