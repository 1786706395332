import React from 'react';
// import PropTypes from 'prop-types';
import { CGViewContext } from '../../app/CGViewContext';
import { ListItem } from '../../presenters/ListItem';
import { SequenceSelector, ExtractSequenceRanges } from '../../containers/SequenceSelector';
import Button from '../../presenters/Button';
import ImageButton from '../../presenters/ImageButton';
import * as d3 from 'd3';
// import * as helpers from '../../support/Helpers';
// import './SequenceDownload.css';

class SequenceDownload extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      infoOpen: false,
      // infoOpen: true,
      concatenateContigs: false,
      // Default seqOptions
      seqOptions: {
        category: 'map',
        sequenceType: 'dna',
        subcategory: 'contigs',
      },
    }

    this.clickDownload = this.clickDownload.bind(this);
    this.infoClicked = this.infoClicked.bind(this);
    this.mainRenderer = this.mainRenderer.bind(this);
    this.infoRenderer = this.infoRenderer.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  infoClicked() {
    this.setState( (state) => ({ ...state, infoOpen: !state.infoOpen }));
  }

  onChange(seqOptions) {
    this.setState( (state) => ({ seqOptions: {...state.seqOptions, ...seqOptions} }));
  }

  mainRenderer() {
    return (
      <div className='list-item-content'>
        <div className='list-item-title'>
          Sequences
        </div>
         <ImageButton
            imageName='download'
            title='Download Sequence'
            className='download-button'
            onClick={ this.clickDownload }
          / >
      </div>
    );
  }

  infoRenderer() {
    const sidebar = this.context.sidebarRef;
    return (
      <div className='info-download-image'>
        <div style={{marginBottom: '10px'}}>
          Download contig or feature sequences (DNA or protein) in fasta format. For a custom selection of features, first select your features in the <a href='#' onClick={() => sidebar.selectPane('features')}>Features Pane</a>.
        </div>
        <SequenceSelector
          label='Choose Sequences to Download'
          categories={['map', 'features']}
          onChange={this.onChange}
        />
        <div className='download-image'>
          <ImageButton
            className='btn-download-image'
            imageName='download'
            title='Download Sequences' 
            size='large'
            text='Download'
            width={105}
            onClick={ this.clickDownload }
          />
        </div>
      </div>
    );
  }

  clickDownload() {
    const { seqOptions } = this.state;
    const cgv = this.context.cgv;

    console.log(seqOptions)
    const seqRegions = new ExtractSequenceRanges(cgv, seqOptions);
    const fasta = seqRegions.asFasta();
    // Date for the file name
    // e.g. sequences.2021.05.18-14.23.10.fa
    const format = d3.timeFormat("%Y.%m.%d-%H.%M.%S");
    const date = format(new Date());
    // console.log(seqOptions)

    cgv.io.download(fasta, `sequences.${date}.fa`)
  }

  render() {
    return (
      <div className='SequenceDownload'>
        <ListItem
          mainRenderer={this.mainRenderer}
          infoRenderer={this.infoRenderer}
          infoOpen={this.state.infoOpen}
          infoClicked={this.infoClicked}
        />
      </div>
    );
  }
}

SequenceDownload.contextType = CGViewContext;

export default SequenceDownload;

