import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './FileInput.css';
import '../support/CommonStyles.css';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import TextInput from './TextInput';
import ProkseeFile from '../models/ProkseeFile';
import HelpElement from './HelpElement';
import * as helpers from '../support/Helpers';

import Uppy from '@uppy/core';
import UppyXHRUpload from '@uppy/xhr-upload';
// import UppyFileInput from '@uppy/file-input'
// import UppyStatusBar from '@uppy/status-bar'
// import { FileInput as UppyFileInput, StatusBar as UppyStatusBar } from '@uppy/react'
import UppyStatusBar from '@uppy/react/lib/StatusBar'; // This reducing js file size
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/status-bar/dist/style.css';

class FileInput extends React.Component {

  static propTypes = {
    className:      PropTypes.string,
    defaultSource:    PropTypes.oneOf(['upload', 'ncbi', 'myData']),
    sourcesAllowed:   PropTypes.array,
    // TODO: change to fieldIDRoot
    // - we will add attachment for file and accession for ncbi (these are hardcoded to match DataFile.rb for now)
    // - this allows submitting the file data via html instead of JavaScript
    fieldName:      PropTypes.string,
    fileType:       PropTypes.oneOf(['sequence', 'text', 'csv', 'cgview', 'fastq']),
    help:           PropTypes.string,
    helpMore:       PropTypes.node,
    validationRules: PropTypes.object, // Passed to specific file handler
    uploadPlaceHolder: PropTypes.string,
    onValidate:     PropTypes.func,
    onChange:       PropTypes.func,
    useUppy:        PropTypes.bool,

    // disabled: PropTypes.func,
  }

  static defaultProps = {
    defaultSource: 'upload',
    sourcesAllowed: ['upload', 'ncbi', 'myData'],
    fieldName: '',
    help: '',
    onValidate: () => {},
    onChange: () => {},
    uploadPlaceHolder: 'Upload file...',
    useUppy: false
    // disabled: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      source: props.defaultSource,
      pfiles: {}, // keys: upload, ncbi
      ncbiAccession: '',
      accessionTyped: false,
      uppyFileData: '',
    }
    this.fileInputRef = React.createRef();
    this.inputForSource = this.inputForSource.bind(this);
    this.onUploadChange = this.onUploadChange.bind(this);
    this.onNCBIChange = this.onNCBIChange.bind(this);
    this.onCheckNCBI = this.onCheckNCBI.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.validate = this.validate.bind(this);
    this.renderValidationErrors = this.renderValidationErrors.bind(this);

    if (props.useUppy) {
      this.initializeUppy();
    }
  }

  initializeUppy() {
    this.uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        // onBeforeFileAdded: (currentFile, files) => {
        //   console.log('BEFORE ADD')
        // },
        // onBeforeUpload: (files) => {
        //   console.log('BEFORE UPLOAD')
        // }
      }
    });
    this.uppy.on('upload-success', (file, response) => {
      var uploadedFileData = JSON.stringify(response.body)
      this.setState({uppyFileData: uploadedFileData});
      // console.log(uploadedFileData)
      this.validate();
    })
    // this.uppy.on('file-added', (file) => {
    //   console.log('ADDED')
    //   console.log(file)
    // })
    this.uppy.use(UppyXHRUpload, {
      endpoint: '/upload',
    })
  }

  componentWillUnmount() {
    if (this.uppy) {
      this.uppy.close();
    }
  }

  onChangeSource(source) {
    this.setState({ source }, this.validate);
    const { pfiles } = this.state;
    const pfile = pfiles[source];
    this.onChange(pfile);
  }

  sourceButtons() {
    const { sourcesAllowed } = this.props;
    const { source } = this.state;
    const sourceMap = {
      upload: 'Upload',
      ncbi:   'NCBI',
      myData: 'My Data',
    }

    const buttons = sourcesAllowed.map( (m) => (
      <Button width={55} key={m}
       active={source === m}
       onClick={ () => this.onChangeSource(m)}>
       {sourceMap[m]}
      </Button>
    ));
    return ( <ButtonGroup>{buttons}</ButtonGroup> );
  }

  updatePFiles(newPFiles) { 
    this.setState((state) => {
      return {
        pfiles: {
          ...state.pfiles,
          ...newPFiles,
        }
      };
    // Only call validate here when not using Uppy. Uppy will call validate on it's own.
    }, this.props.useUppy ? () => {} : this.validate);
  }

  validate() {
    const { onValidate, useUppy } = this.props;
    const { pfiles, source } = this.state;
    const pfile = pfiles[source];
    const validationErrors = pfile && pfile.validationErrors || [];
    if (!pfile || validationErrors.length > 0) {
      useUppy && this.uppy.reset()
      onValidate(false);
    } else {
      onValidate(true);
    }
  }

  onChange(pfile) {
    const { onChange } = this.props;
    onChange(pfile);
  }


  onUploadChange(e) {
    const { fileType, validationRules, useUppy } = this.props;
    const fileList = e.target.files;
    const file = event.target.files[0];
    const pfile = new ProkseeFile({fileType, fileValue: file, validationRules});
    pfile.parse()
    .then(f => {
      // console.log(pfile)
      // console.log(pfile.details())
      if (useUppy) {
        this.addUppyFile(file)
      }
      this.updatePFiles({ upload: pfile });
      this.onChange(pfile);
    });
  }

  onNCBIChange(accession) {
    // Remove trailing text (after and incluing first whitespace)
    const adjustedAccession = accession.replace(/\s.*/, '');
    this.setState({
      ncbiAccession: adjustedAccession,
      accessionTyped: true,
    });
  }

  onCheckNCBI(force=false) {
    const { fileType, validationRules } = this.props;
    const { ncbiAccession, accessionTyped } = this.state;
    this.setState({ accessionTyped: false });
    const accession = ncbiAccession.trim();
    if (accessionTyped || force) {
      // console.log(`"${accession}"`);
      if (accession === "") {
        this.updatePFiles({ ncbi: undefined });
        // TODO: we may need to call onChange with an empty file here
      } else {
        const pfile = new ProkseeFile({fileType, fileValue: accession, validationRules});
        pfile.parse()
        .then(f => {
          this.updatePFiles({ ncbi: pfile });
          // console.log(pfile.details())
          this.onChange(pfile);
        });
      }
    }
  }

  onMouseMove() {
    if (this.state.accessionTyped) {
      this.onCheckNCBI();
    }
  }

  // Creates a name attribute using the base provided as a prop
  // This is specific to the Proksee DataFile model which has 2 key attributes:
  // - attachment: for file uploads
  // - external_id: for ncbi files
  // To prevent both being sent, the field name will only be provided for the active source
  fieldName(source) {
    const {fieldName} = this.props;
    const attributeMap = {upload: 'attachment', ncbi: 'external_id'};
    // console.log(this.state.source)
    // console.log( (source === this.state.source) ? `${fieldName}[${attributeMap[source]}]` : '');
    if (fieldName === '') {
      return '';
    }
    return (source === this.state.source) ? `${fieldName}[${attributeMap[source]}]` : '';
  }

  uploadInput() {
    const { fieldName, uploadPlaceHolder } = this.props
    const { pfiles } = this.state
    const name = pfiles.upload && pfiles.upload.fileName || uploadPlaceHolder;
    return (
      <div className='my-file'>
        <span className='my-file-control' onClick={ () => { this.fileInputRef.current.click() }} >{name}</span>
      </div>
    )
  }

  ncbiInput() {
    return (
      <ButtonGroup className='my-accession'>
        <TextInput
          autoFocus={true}
          placeholder='Enter a GenBank Accession (e.g. NC_000913)'
          value={this.state.ncbiAccession}
          onChange={this.onNCBIChange}
          onBlur={this.onCheckNCBI}
        />
        <Button onClick={ () => this.onCheckNCBI(true) }>Check</Button>
      </ButtonGroup>
    );
  }

  inputForSource() {
    const { source } = this.state;
    return this[`${source}Input`]();
  }

  helpText() {
    const { help, helpMore } = this.props;
    return <HelpElement text={help} moreText={helpMore} />
  }

  renderFileDetails() {
    const { pfiles, source } = this.state;
    const { validationRules, useUppy } = this.props;
    const pfile = pfiles[source];
    // TODO: show Uppy file data here
    if (pfile) {
      const contents = pfile.fileError ? <div>ERROR: {pfile.fileError}</div> : <div>{ProkseeFile.detailsRenderer(pfile.details(), validationRules)}</div>;
      return <div className='file-details open'>{contents}</div>;
    } else {
      return <div className='file-details closed' />;
    }
  }

  renderValidationErrors() {
    const { pfiles, source } = this.state;
    const pfile = pfiles[source];
    const validationErrors = pfile && pfile.validationErrors || [];
    if (validationErrors.length > 0) {
      const errors = validationErrors.map( e => <div key={e}>{e}</div> );
      return <div className='file-errors open'>{errors}</div>;
    } else {
      return <div className='file-errors closed' />;
    }
  }

  // Temporary way of mapping fileSource to DataFile.rb source.
  // fileSource will eventually be synced to DataFile.rb source
  dataFileSourceTemp() {
    const { source } = this.state;
    const sourceMap = {
      'upload': 'upload',
      'myData': 'picker', // Not used yet
      'ncbi': 'external',
    }
    return sourceMap[source];
  }

  externalDB() {
    const { source, ncbiAccession } = this.state;
    if (source === 'ncbi') {
      return <input type='hidden' name={`${this.props.fieldName}[external_db]`} value={'ncbi'} />
    }
  }

  addUppyFile(file) {
    const { onValidate } = this.props;
    try {
      // Reset uppy and validation when adding a file
      this.uppy.reset();
      onValidate(false);
      this.uppy.addFile({
        source: 'file input',
        name: file.name,
        type: file.type,
        data: file,
      })
    } catch (err) {
      if (err.isRestriction) {
        // handle restrictions
        console.log('Restriction error:', err)
      } else {
        // handle other errors
        console.error(err)
      }
    }
  }

  renderUppy() {
    const { uppyFileData } = this.state;
    return (
      <div>
        <UppyStatusBar
          uppy={this.uppy}
          hideUploadButton
          hideAfterFinish={false}
          showProgressDetails
          locale={{strings: {complete: 'Upload Complete'}}}
        />
        <input type='hidden' name={this.fieldName('upload')} value={uppyFileData} />
      </div>
    );
  }
  
  renderInputForFile() {
    const { useUppy } = this.props;
    const { source } = this.state;
    const inputName = useUppy ? '' : this.fieldName('upload');
    return (
      <div>
        <input type='file'
          tabIndex="-1"
          className='my-file-input'
          ref={this.fileInputRef}
          onChange={this.onUploadChange}
          name={inputName}
        />
        { useUppy && source == 'upload' && this.renderUppy() }
      </div>
    );
  }

  render() {
    const { className } = this.props;
    const { source, ncbiAccession } = this.state;
    const klass = classNames('FileInput', className);

    return (
      <div className={klass} onMouseMove={this.onMouseMove} >
        <div className='input-controls'>
          { this.sourceButtons() }
          { this.inputForSource() }
        </div>
        { this.helpText() }
        { this.renderValidationErrors() }
        { this.renderFileDetails() }

        { this.renderInputForFile() }

        <input type='hidden' name={`${this.fieldName('ncbi')}`} value={ncbiAccession} />
        <input type='hidden' name={`${this.props.fieldName}[source]`} value={this.dataFileSourceTemp()} />
        { this.externalDB() }
      </div>
    );
  }
}

export default FileInput;

