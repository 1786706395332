import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';
import './DialogAdd.css'
import classNames from 'classnames';
import Papa from 'papaparse'

class DialogAdd extends DefaultDialog {

  renderMoreHelp() {
    return (
      <div className='feature-help'>
        <p>
          The file should contain tab-separated or comma-separated values,
          and should have the following column titles, in any
          order: 
        </p>
        <table>
          <thead><tr><th>Column</th><th>Description</th></tr></thead>
          <tbody>
            <tr><td>name</td><td>Name of the feature</td></tr>
            <tr><td>type</td><td>Type of feature (e.g. 'CDS', 'rRNA', 'tRNA')</td></tr>
            <tr><td>start*†</td><td>Number between 1 and the length of the contig or map sequence</td></tr>
            <tr><td>stop*†</td><td>Number between 1 and the length of the contig or map sequence</td></tr>
            <tr><td>strand</td><td>'+' for the forward strand and '-' for the reverse strand [Default: +]</td></tr>
            <tr><td>contig</td><td>Name of the contig the feature is on. Start/stop positions are relative to the contig. If no contig is provided, the positions will be relative to the entire map sequence.</td></tr>
          </tbody>
        </table>
        <p>* Required columns</p>
        <p>
          † The <em>start</em> should be less than or equal to the <em>stop</em>, regardless of the <em>strand</em>. 
          If the <em>start</em> is greater than the <em>stop</em>, the feature will wrap around the origin.
        </p>
        <p>
          Example:
        </p>
        <pre>
{`name  contig type start stop  strand
gene1 con1   CDS  103   1500  +
gene2 con1   CDS  2400  3623  -
gene2 con2   tRNA 1600  1700  +`}
        </pre>
      </div>
    )
  }

  // value will be file.details()
  onFileChange({attribute, value}) {
    this.onChange({attribute, value});
    this.setState({fileName: value.fileName});
    this.checkFile(value.text);
  }

  checkFile(text) {
    let unknownContigs = [];
    let startOverStopRows = [];
    let constrainedRows = [];
    let noContigColumn, showConstrainedFeatures, showStartOverStopFeatures;
    const cgv = this.cgv;
    const features = [];
    //TODO: create helper for reading csv with the following defaults
    // Using same settings as in file_delegates/CSVFile.js
    const csv = Papa.parse(text, {
      header: true,
      skipEmptyLines: true,
      comments: '#',
      transformHeader: (h) => h.trim().toLowerCase(),
      transform: (d) => d.trim(),
      error: (error, file) => {console.log(error)}
    });
    this.csv = csv;
    // Check for contig columns and unknown contigs
    const headings = csv.meta.fields;
    const mapContigNames = cgv.contigs().map( c => c.name );
    if (headings.includes('contig')) {
      const fileContigNames = csv.data.map( d => d.contig );
      const unknownContigs = [...new Set(fileContigNames.filter(x => !mapContigNames.includes(x)))];
    } else {
      if (cgv.sequence.hasMultipleContigs) {
        noContigColumn = true;
      }
    }
    // Remove features with unknown contigs or that are constrained (i.e. start/stop > than contig/sequence length)
    for (const [index, row] of csv.data.entries()) {
      const start = Number(row.start);
      const stop = Number(row.stop);
      // Problem: startOverStop
      if (start > stop) {
        startOverStopRows.push(index)
      }
      // Problem: constrained
      let length = cgv.sequence.length;
      if (row.contig) {
        if (mapContigNames.includes(row.contig)) {
          const contig = cgv.contigs(row.contig);
          length = contig.length;
        } else {
          // Unknown contig will be ignored
          continue;
        }
      }
      if (start > length || stop > length) {
        constrainedRows.push(index)
        // Ignore constrained features
        continue;
      }
      features.push(row);
    }
    this.setState({
      unknownContigs,
      startOverStopRows,
      constrainedRows,
      noContigColumn,
      showConstrainedFeatures,
      showStartOverStopFeatures,
      mapData: { features },
    });
  }

  renderUnknownContigs() {
    const { unknownContigs } = this.state;
    if (unknownContigs && unknownContigs.length > 0) {
      return (
        <div className='file-issue'>
          <p className='heading'>The following contigs do not exist in the map:</p>
          <div className='feature-list'>{unknownContigs.join(', ')}</div>
          <p><em>Features with these contigs will be ignored.</em></p>
        </div>
      )
    }
  }

  renderNoContigColumn() {
    const { noContigColumn } = this.state;
    if (noContigColumn) {
      return (
        <div className='file-issue'>
          <p className='heading'>No <em>contig</em> column was found</p>
          <p>
            Your map has mulitple contigs.
            Features will be added to the Map Sequence and will be independent of the contigs.
            This means if contigs are later reordered, the features will not move.
            This may have odd side effects.
          </p>
        </div>
      )
    }
  }

  renderStartOverStopFeatures() {
    const { startOverStopRows, showStartOverStopFeatures } = this.state;
    if (startOverStopRows && startOverStopRows.length > 0) {
      const headings = this.csv.meta.fields;
      const rows = startOverStopRows.map( i => ({row: i, ...this.csv.data[i]}));
      return (
        <div className='file-issue'>
          <p className='heading'>Features found with the <em>start</em> <strong>&gt;</strong> <em>stop</em>: &nbsp;
            <a href='#'
              onClick={ () => this.setState({showStartOverStopFeatures: !showStartOverStopFeatures}) }
            >{startOverStopRows.length}</a></p>
          { showStartOverStopFeatures &&
            <div className='feature-list my-test'>
              {rows.map( r => <p key={r.row}><strong>{r.row + 1}</strong>: {headings.map( h => r[h] ).join(', ')}</p>)}
            </div>
          }
          <p><em>These features will wrap around the entire map.</em></p>
          {this.renderInput('swapStartStop')}
        </div>
      )
    }
  }

  renderConstrainedFeatures() {
    const { constrainedRows, showConstrainedFeatures } = this.state;
    if (constrainedRows && constrainedRows.length > 0) {
      const headings = this.csv.meta.fields;
      const rows = constrainedRows.map( i => ({row: i, ...this.csv.data[i]}));
      return (
        <div className='file-issue'>
          <p className='heading'>Features out of range: &nbsp;
            <a href='#'
              onClick={ () => this.setState({showConstrainedFeatures: !showConstrainedFeatures}) }
            >{constrainedRows.length}</a></p>
          { showConstrainedFeatures &&
            <div className='feature-list'>
              {rows.map( r => <p key={r.row}><strong>{r.row + 1}</strong>: {headings.map( h => r[h] ).join(', ')}</p>)}
            </div>
          }
          <p><em>These features have a start/stop position beyond the length of their contig and <strong>will be ignored</strong>.</em></p>
        </div>
      )
    }
  }

  renderFeatureCount() {
    const { mapData } = this.state;
    if (mapData && mapData.features) {
      return (
        <div className='file-note'>
          Feature that will be added: &nbsp;{mapData.features.length}
        </div>
      )
    }
  }
  // Use of 'key' will cause the component to be recreated if the key changes.
  // This calls the constructor which internally will call returnCurrentItem.
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#preferred-solutions
  renderContents() {
    const { tool } = this.props;
    const { options, fileName } = this.state;
    const newLegendName = (options.legend && options.legend.name) || fileName;
    const newTrackName = (options.track && options.track.name) || fileName;
    const legendID = (options.legend && options.legend.cgvID) || tool.inputs.get('legend').default;
    const trackID = (options.track && options.track.cgvID) || tool.inputs.get('track').default;

    return (
      <div>
        {this.renderHeader()}
        {this.renderInput('file', {
          helpMore: this.renderMoreHelp(),
          onValidate: this.onValidate,
          onChange: (file) => this.onFileChange({attribute: 'file', value: file.details({includeText: true})}),
        })}
        {this.renderUnknownContigs()}
        {this.renderNoContigColumn()}
        {this.renderConstrainedFeatures()}
        {this.renderStartOverStopFeatures()}
        {this.renderFeatureCount()}
        {this.renderInput('track', {defaultNewItemName: newTrackName, default: trackID, key: fileName})}
        {this.renderInput('legend', {defaultNewItemName: newLegendName, default: legendID, key: fileName})}

        {this.renderTips()}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogAdd;

